<!-- 默认按钮 -->
<template>
  <button class="qi-button-big" @click="handleClick" :disabled="isLoading">
    <span v-if="isLoading" class="loader"></span>
    <span v-else>
      <slot></slot>
    </span>
  </button>
</template>

<script>
export default {
  props: {
    // 按钮文本
    text: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      isLoading: false // 用于控制 loading 状态
    };
  },
  methods: {
    handleClick() {
      this.isLoading = true; 
      this.$emit('click')
      this.isLoading = false;
    }
  }
}
</script>

<style scoped>
.qi-button-big {
  margin: 0;
  background-color: var(--theme-color);
  border: 1px solid var(--button-default-border);
  color: var(--header-font-color);
  padding: 0 22px;
  width: 100%;
  line-height: 2.42857143;
  vertical-align: middle;
  text-align: center;
  border-radius: 3px;
  font-size: 14px;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  box-sizing: content-box;
  cursor: pointer;
  /* 鼠标指针 */
  position: relative;
  /* 使 loader 可以绝对定位 */
}
.qi-button-big:hover{
  background-color: #1a7d62;
}
.qi-button-big:disabled {
  background-color: #ccc;
  /* 禁用状态样式 */
  cursor: not-allowed;
  /* 禁止光标 */
}

.loader {
  border: 3px solid rgba(255, 255, 255, 0.3);
  /* loader 边框颜色 */
  border-top: 3px solid white;
  /* loader 顶部颜色 */
  border-radius: 50%;
  /* 圆形 */
  width: 15px;
  /* loader 宽度 */
  height: 15px;
  /* loader 高度 */
  animation: spin 1s linear infinite;
  /* 动画效果 */
  position: absolute;
  /* 绝对定位 */
  left: 50%;
  /* 居中 */
  top: 50%;
  /* 居中 */
  transform: translate(-50%, -50%);
  /* 完全居中 */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1800px) {

  .qi-button-big {
    padding: 0 7.5px;
  }
}
</style>