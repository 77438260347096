<template>
  <div class="pc">
    <!--定义Vue组件的模板内容-->
    <!-- <div class="history flex-y-between-start">555</div> -->
    <div class="problem-view">
      <span class="top">
        <!-- <el-button @click="fadeStatus = ''"></el-button>
        <el-button @click="fadeStatus = 'fadeIn'">fadeIn</el-button>
        <el-button @click="fadeStatus = 'fadeOut'">fadeOut</el-button> -->
        企业ChatGPT&hairsp;<i class="iconfont icon-ditu-yuan"></i>
      </span>
      <div class="answer" :class="fadeStatus">
        <p class="question">{{ questionText }}</p>
        <!-- <span class="problem">
          {{ typedText }}
          <transition name="el-zoom-in-top">
            <div
              class="iconfont icon-ditu-yuan"
              :class="ball ? '' : ' iconFadeOut'"
            ></div>
          </transition>
        </span> -->
        <span class="problem" v-html="typedText"></span>
        <span class="iconfont icon-ditu-yuan" :class="ball ? '' : ' iconFadeOut'"></span>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapState } from 'vuex' // 从store中导入【全局】数据
// 定义Vue组件的业务逻辑
export default {
  // 为组件命名
  // name:'',
  // 加载子目录组件👇
  // components: {
  // } ,
  // 如果作为子组件向父组件传递
  props: {
    // xxx: { // xxx 引导子组件向父组件👆传值的要素,父组件里要有定义
    //   type: Object,
    //   required: true
    // }
  },

  // 私有数据
  data() {
    return {
      textQuestion: "", // 提问文本
      questionText: "", // 显示提问区域
      questionTextIndex: 0, // ↑ 字体长度

      textProblem: "", // 回答文本
      typedText: "", // 显示回答区域
      currentIndex: 0, // ↑ 字体长度

      fadeStatus: "", //fadeIn 淡入 fadeOut 淡出
      changeValue: null, // 切换文本
      toTop: null,
      ball: false,
      end: false,
    }
  },
  // 创建生命周期函数,立即获取所有元素
  // 当页面刚加载的时候会执行的钩子函数
  created() {
    //------------------------------------
    this.showText() // 开始动画
  },
  // 处理函数
  methods: {
    async showText() {
      try {
        await this.noEnd()
        await this.showText01("头脑风暴", "为企业人工智能产品想一个Slogan ", "")
        await this.showText01("给我建议", "一套女性在夏天在见客户的搭配 ", "")
        await this.showText01("写创意文案","写一份巴西蜂蜜的小红书文案给30岁女性 ","")
        await this.showText01("总结文章", "总结一下这篇超导论文的核心观点 ", "")
        await this.showText01("做流程计划","帮我制定一个招聘宣讲会的流程计划 ","")
        await this.showText01("开发程序","给出图书管理系统的后端架构和主要代码 ","")
        await this.showText01("课程开发", "列出一份高中数学复习教案 ", "")
        await this.theEnd()
        // 其他步骤...
      } catch (error) {
        console.error("error")
      }
    },
    showText01(text01, text02, text03) {
      return new Promise((resolve, reject) => {
        let seconds = 1
        this.fadeStatus = ""
        const intervalText = setInterval(() => {
          this.fadeStatus = "fadeIn" // 淡入动画
          this.questionText = text01
          this.textProblem = text02
          this.ball = true
          seconds--
          if (seconds <= 0) {
            clearInterval(intervalText)
            this.startTyping(text03)
              .then(() => {
                resolve()
              })
              .catch((error) => {
                reject(error) // 执行失败时调用 reject()
              })
          }
        }, 1000)
      })
    },
    startTyping(text03) {
      // 生成文字
      return new Promise((resolve, reject) => {
        this.typedText = ""
        this.currentIndex = 0
        const intervalId = setInterval(() => {
          this.typedText += this.textProblem[this.currentIndex]
          this.currentIndex++
          if (this.currentIndex >= this.textProblem.length - 1) {
            clearInterval(intervalId)
            this.fadeOutCartoon() // 文字淡出动画
            setTimeout(() => {
              resolve() // 执行成功时调用 resolve()
            }, 3500)
          }
        }, 100)
      })
    },
    fadeOutCartoon() {
      // 文字淡出动画
      this.ball = false
      setTimeout(() => {
        //结束瞬间，执行光标消失动画
        this.fadeStatus = "fadeOut" // 三秒后，执行淡出语句
      }, 3000)
    },
    // startTyping01(text03) {
    //   this.textProblem = text03
    // },
    noEnd() {
      this.end = false
    },
    theEnd() {
      this.end = true
    },
  },

  // 将【全局】数据,映射为当前组件的计算属性
  computed: {
    
  },
  // ...其他业务逻辑
  watch: {
    currentIndex(newVal) {
      if (!newVal) {
        this.num++
      }
    },
    fadeStatus(newVal) {
      if (!newVal) {
        clearInterval(this.toTop)
      }
    },
    end(newVal) {
      // if (newVal) setTimeout(() => {this.showText()}, 1000);
      if (newVal) this.showText()
    },
  },
}
</script>

<style lang="less" scoped>
/**这个语法中支持less scoped只在当前组建生效，没有则全局生效 */
/**定义组件的形式 */
.pc {
  height: 100%;
  color: #0fa47f;
  .problem-view {
    flex: 2;
    padding: 16px;
    height: 100%;
    position: relative;
    .answer,
    .question {
      align-items: flex-start;
      text-align: left;
      font-size: 40px / 1.45;
      font-weight: 700;
    }
    .problem {
      font-weight: var(--fontWeightNormal);
    }
    .answer {
      // 默认隐藏
      position: absolute;
      top: 50%;
      transform: translate(0px, -51%);
      opacity: 0;
      transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    }
    .fadeIn {
      transform: translateY(-50%);
      opacity: 1;
    }
    .fadeOut {
      transform: translateY(-25%);
      opacity: 0;
    }
    .top {
      // color: #fff;
      // background-color: #437eb4;
      color: #0fa47f;
      font-weight: 700;
      font-size: 22px;
      .iconfont {
        font-size: 22px;
      }
    }
    .question {
      line-height: 1.3;
      line-height: 2;
    }
    .problem {
      // display: inline-block;
      max-width: 495px;
      font-size: 40px / 1.45;
      line-height: 1.25;
      i {
        font-size: 40px / 1.45;
      }
    }
    .iconfont {
      font-size: 22px;
      height: 25px;
      line-height: 25px;
      width: 25px;
      display: inline;
    } 
    .iconFadeOut {
      display: inline-block; 
      -webkit-animation: scaleAnimation 3.5s infinite;
      animation: scaleAnimation 3.5s infinite;
    }
    // 圆点缩放动画
    @keyframes scaleAnimation {
      0% {
        -webkit-transform: scale(1);
        transform: scale(1);
      }
      15% {
        -webkit-transform: scale(1);
        transform: scale(1);
      }
      20% {
        -webkit-transform: scale(0);
        transform: scale(0);
        transform-origin: 50% 25%;
        -webkit-transform-origin: 50% 25%;
      }
      100% {
        -webkit-transform: scale(0);
        transform: scale(0);
        transform-origin: 50% 25%;
        -webkit-transform-origin: 50% 25%;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .pc .problem-view .answer i,
  .pc .problem-view .question img {
    // margin-left: 16px;
    margin-left: 6px;
  }
}
</style>
