export default {
  /**首页 */
  让每个人都用上人工智能: "让每个人都用上人工智能",
  简单好用的人工智能: "简单好用的人工智能",
  /**支付页面 */
  创建订单: "创建订单",
  次: "次",
  原价: "原价",
  截止: "截止",
  小计: "小计",
  联系信息: "联系信息",
  邮箱: "邮箱",
  购买数量: "购买数量",
  可用次数: "可用次数",
  总计: "总计",
  请扫码支付: "请扫码支付",
  /**留言板 */
  发送: "发送",
  留言内容不得为空: "留言内容不得为空",
  /**我的信息 */
  返回并提问: "返回并提问",
  姓名是必填字段: "姓名是必填字段",
  用户配置文件更新成功: "用户配置文件更新成功",
  头像上传成功: "头像上传成功",
  头像上传失败: "头像上传失败",
  /**对话页面 */
  新聊天: "新聊天",
  意见建议: "意见建议",
  请留下您的宝贵意见: "请留下您的宝贵意见，这对我们非常重要",
  有效期: "有效期",
  示例: "示例",
  性能: "性能",
  限制: "限制",
  示例集: [
    "简单解释量子计算",
    "10岁生日有什么创意吗?",
    "如何使用Javascript写一个http请求?",
  ],
  性能集: [
    "记住用户之前在对话中说过的话",
    "允许用户提供后续更正",
    "学会拒绝不恰当的请求",
  ],
  限制集: [
    "可能偶尔会产生错误的信息",
    "可能偶尔会产生有害的说明或有针对性的内容",
    "对2021年后的世界和事件了解有限",
  ],
  发送消息: "发送消息...",
  继续对话: "继续对话...",
  提示: "ChatGPT可能会产生关于人、地点或事实的不准确信息。ChatGPT 7月20日版本",
  兑换: "兑换",
  重新生成: "重新生成",
  停止生成: "停止生成",
  复制代码: "复制代码",
  保存并提交: "保存并提交",
  保存: "保存",
  关闭: "关闭",
  仅有一次:
    "一次只能处理一条消息。请等待其他回复完成后再发送另一条信息，或等待一分钟。",
  今日次数已用完: "今日次数已用完",
  会话次数已用完: "会话次数已用完",
  请充值: "请充值",
  连接已断开: "连接已断开",
  秒后自动刷新页面: " 秒后自动刷新页面",
  网络错误: "网络错误，请重试",
  /**我的信息 */
  我的信息: "我的信息",
  退出登录: "退出登录",
  用户档案: "用户档案",
  昵称: "昵称",
  名称: "名称",
  与此帐户关联的昵称: "与此帐户关联的昵称",
  电子邮箱: "电子邮箱",
  与此帐户关联的电子邮件地址: "与此帐户关联的电子邮件地址",
  与此帐户关联的电话号码: "与此帐户关联的电话号码",
  头像: "头像",
  与此帐户关联的头像: "与此帐户关联的头像",
  绑定手机: "绑定手机",
  绑定邮箱: "绑定邮箱",
  上传头像图片大小不能超过点5MB: "上传头像图片大小不能超过 0.5MB",
  /**我的信息 */
  我的信息: "我的信息",
  /**账单页面 */
  订单记录: "订单记录",
  账单: "账单",
  时间: "时间",
  订单号: "订单号",
  可用次数: "可用次数",
  花费: "花费",
  方式: "方式",
  剩余: "剩余",
  天: "天",
  多天: "天",
  payMethodType1: "购买奖励 ",
  payMethodType2: "系统奖励",
  payMethodType3: "注册赠送",
  payMethodType4: "课程赠送",
  payMethodType5: "运营充值",
  /**使用记录页面 */
  使用记录: "使用记录",
  日期: "日期",
  使用情况: "使用情况",
  描述: "描述",
  充值: "充值",
  支付: "支付",
  descType1: "对话消耗",
  descType2: "过期失效",
  descType3: "课程退款",
  /**登录页面 */
  选择语言: "选择语言：",
  欢迎使用中文ChatGPT: "欢迎使用中文ChatGPT",
  用您的帐户登录: "用您的帐户登录",
  登录: "登录",
  注册: "注册",
  hello: "nihao",
  欢迎回来: "欢迎回来",
  还没有账号: "还没有账号",
  去注册: "去注册",
  输入你的密码: "输入你的密码",
  密码: "密码",
  忘记密码: "忘记密码",
  电子邮件无效: "电子邮件无效",
  账号或密码错误: "账号或密码错误",
  使用邮箱登录: "使用邮箱登录",
  手机验证登录: "手机验证登录",
  /**注册 */
  创建新用户: "创建新用户",
  手机: "手机",
  邮箱: "邮箱",
  电话或邮箱: "电话/邮箱",
  确定: "确定",
  已经有账户了: "已经有账户了",
  登录: "登录",
  验证码: "  验证码 ",
  验证码已发送请勿重复获取: "验证码已发送，请勿重复获取",
  发送至邮箱: "发送至邮箱 ",
  获取验证码: "获取验证码",
  确认密码: "确认密码 ",
  密码长度不超过8位: "密码长度少于8位数",
  两次密码输入不一致: "两次密码输入不一致",
  密码不能为纯数字: "密码不能为纯数字",
  请输入验证码: "请输入验证码",
  请输入密码: "请输入密码",
  请确认密码: "请确认密码",
  请输入确认密码: "请输入确认密码",
  邮箱不能为空: "邮箱不能为空 ",
  电话号码不能为空: "电话号码不能为空",
  验证码不能为空: "验证码不能为空 ",
  验证码有误: "验证码有误 ",
  密码不能为空: "密码不能为空 ",
  用户不存在: "用户不存在",
  用户已经存在: "用户已经存在",
  填写格式有误: "填写格式有误",
  请输入合规的邮箱地址或11位大陆电话号码:
    "请输入合规的@邮箱地址或11位大陆电话号码",
  请输入有效的11位手机号码: "请输入有效的11位手机号码",
  请输入有效的电子邮件地址: "请输入有效的电子邮件地址",
  在: "在 ",
  秒后重试: "秒后重试 ",
  短信发送成功: "短信发送成功",
  短信发送失败: "短信发送失败",
  用户名格式无效: "用户名格式无效",
  请输入正确的手机验证码: "请输入正确的手机验证码",
  /**手机登录注册 */
  手机登录: "手机登录",
  验证电话号码: "验证电话号码",
  /**重置 */
  查看电子邮件: "查看电子邮件",
  请检查邮箱的中最新的邮件以获取重置密码链接:
    "请检查邮箱的中最新的邮件，以获取重置密码链接",
  重新发送邮件: "重新发送邮件",
  发送成功: "发送成功",
  重置你的密码: "重置你的密码",
  请输入您的电子邮件地址或电话号码我们将向您发送重置密码的指示:
    "请输入您的电子邮件或电话，我们将向您发送重置密码的指示！",
  请在下面输入新密码以更改密码: "请在下面输入新密码以更改密码",
  无效的电子邮件地址: "无效的电子邮件地址",
  新密码: "新密码",
  重新输入新密码: "重新输入新密码",
  重置密码: "重置密码",
  /**支付方式 */
  充值: "充值",
  去支付: "去支付",
  使用微信支付: "使用微信支付",
  二维码加载失败: "二维码加载失败",
  /**课程兑换 */
  课程兑换: "课程兑换",
  请输入8位兑换码: "请输入8位兑换码",
  密码不能为纯数字: "密码必须包含字母和数字两种组合以上，且不少于8位字符",
  密码不能包含空格: "密码不能包含空格",
  修改成功: "修改成功",
  确认兑换: "确认兑换",
  请输入正确的兑换码: "请输入正确的兑换码",
  兑换码已使用: "兑换码已使用",
  兑换码不存在: "兑换码不存在",
  密码至少包含字母和数字的两种组合且不小于8个字符:
    "密码至少包含字母和数字的两种组合，且不小于8个字符",
  //-------
  兑换成功: "兑换成功",
  充值成功: "充值成功",
  兑换失败: "兑换失败",
  充值失败: "充值失败",
  请再次尝试: "请再次尝试",
  返回: "返回",
  返回兑换页: "返回兑换页",
  返回充值页: "返回充值页",
  获得: "获得",
  次数: "次数",
  tokens: "token",
  我的AI员工: "我的AI员工",
  第三方平台: "第三方平台",
  充值付费: "充值付费",
  修改: "修改",
  绑定或修改手机号: "绑定或修改手机号",
  绑定或修改邮箱: "绑定或修改邮箱",
  微信公众号: "微信公众号",
  授权: "授权",
  AppID: "AppID",
  请前往: "请前往",
  公众号后台获取APPID: "公众号后台获取APPID",
  并: "并",
  扫码授权: "扫码授权",
  已授权: "已授权",
  如需解除授权: "如需解除授权",
  公众号后台授权管理: "公众号后台授权管理",
  解除授权: "解除授权",
}
