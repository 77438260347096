<!-- AI员工详情展示页 -->
<template>
  <div class='agentView flex-y-between' :style="'height:calc(100% - ' + footerHeight + 'px)'">
    <img v-if="!avatar" src="../../assets/images/icons/agent.png" alt="" srcset="">
    <img v-else :src="avatar" alt="" srcset="">
    <div class="name">{{ created_name }}</div>
    <QI-Note class="desc" :value="desc"></QI-Note>
  </div>
</template>

<script>
// import { mapState } from 'vuex' // 从store中导入【全局】数据
// 定义Vue组件的业务逻辑
export default {
  // 为组件命名
  name: 'agentView',
  // 加载子目录组件👇
  // components: {
  // } ,
  // 如果作为子组件向父组件传递
  props: {
    avatar: { // 头像
      type: String,
      required: true
    },
    created_name: { // AI员工名称
      type: String,
      required: true
    },
    desc: { // 描述
      type: String,
      required: true
    },
    footerHeight: { // 输入框模块高度
      type: Number,
      required: false
    },
  },

  // 私有数据
  data() {
    return {}
  },
  // 创建生命周期函数,立即获取所有元素
  // 当页面刚加载的时候会执行的钩子函数
  created() { },
  // 处理函数
  methods: {},

  // 将【全局】数据,映射为当前组件的计算属性
  computed: {
    
  },

  // ...其他业务逻辑
}
</script>

<style lang='less' scoped>
.agentView {
  width: 100%;
  margin:0 auto;
  padding: 0 125px;
    .desc {
      font-size: 14px !important;
    }

  img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  .name {
    margin: 1rem 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
  }


}

@media screen and (max-width: 1800px) {
  .agentView {
    width: 383px;
    margin: 0 auto;
    padding: 0;
    .desc {
      padding: 0;
    }
  }
}
@media screen and (max-width: 768px) {
.agentView {
  width: 100%;
  margin-top: 10rem;
  .qi-note {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  }
}
</style>