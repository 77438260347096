<!-- newChat按钮 -->
<template>
  <div class='chat flex-y-right' @click="showSelect">
    <!--定义Vue组件的模板内容-->
    <!-- <el-tooltip class="item" effect="dark" :content="'新会话'" placement="top"> -->
    <div class="add mouseHead">+</div>
    <div class="selects mouseHead" v-for="(i, index) in funs" :key="index + 1" v-if="select">
      <div class="option" @click="opFuntions(index)">
        {{ i.text }}
      </div>
    </div>
    <!-- </el-tooltip> -->
    <el-dialog :title="alertTitle" :visible.sync="dialogVisible" width="720px" :before-close="handleClose">
      <div class="flex-x-center-centerHor" style="height: 100%;padding-bottom: 10px;">
        <i class="el-icon-warning"></i>
        <div class="flex-y-left">
          <p>{{ alertContent.split('，')[0] }}</p>
          <p style="color: #9a9a9a;">{{ alertContent.split('，')[1] }}，{{ alertContent.split('，')[2] }}</p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <button class="content_label_btn" type="primary" @click="confirm()">确定</button>
        <button class="content_label_btn_default" @click="dialogVisible = false;">取消</button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import { mapState } from 'vuex' // 从store中导入【全局】数据
import { deleteMarketChatSession } from "../../config/api.js";

// 定义Vue组件的业务逻辑
export default {
  // 为组件命名
  name: 'chat',
  // 加载子目录组件👇
  // components: {
  // } ,
  // 如果作为子组件向父组件传递
  props: {
    emp_id: { // xxx 引导子组件向父组件👆传值的要素,父组件里要有定义
      type: String,
      required: false
    }
  },

  // 私有数据
  data() {
    return {
      select: false,
      funs: [
        { text: '删除会话' }
      ],
      dialogVisible: false,
      alertTitle: "",
      alertContent: "",
    }
  },
  // 创建生命周期函数,立即获取所有元素
  // 当页面刚加载的时候会执行的钩子函数
  created() { },
  mounted() {
    document.addEventListener('click', this.handleOutsideClick);
  },
  // 处理函数
  methods: {
    showSelect() {
      this.select = !this.select
    },
    opFuntions(index) {
      if (index == 0) { // 删除会话
        this.showDel()
      }
    },
    showDel() {
      this.alertBubble('温馨提示', '删除对话，删除后，对话记录将不可恢复');
    },
    delChat() {
      deleteMarketChatSession({emp_id: this.emp_id}).then(res => {
      }).catch((err) => {
        if (err) {
          this.$message.error(error);
        }        
      });
    },
    alertBubble(title, content, emp_id, value) {
      this.dialogVisible = true;
      this.alertTitle = title
      this.alertContent = content
    },
    async confirm(){
      await this.delChat(); //执行删除指令
      await this.newChat(); // 创建新会话
    },
    newChat() {
      this.$emit('click', false)
    },
    handleOutsideClick(event) {
      // 检查点击是否发生在 selects 元素内部
      if (!this.$el.contains(event.target)) {
        this.select = false;
      }
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },

  // 将【全局】数据,映射为当前组件的计算属性
  computed: {
    
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleOutsideClick);
  },
  // ...其他业务逻辑
}
</script>

<style lang='less' scoped>
/**这个语法中支持less scoped只在当前组建生效，没有则全局生效 */
/**定义组件的形式 */
.chat {
  position: absolute;
  top: 15px;
  right: 15px;

  .add,
  .selects {
    background: #fff;
  }

  .add {
    z-index: 1;
    font-size: 22px;
    color: var(--icon-black);
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15), 0 0 1px 0 rgba(0, 0, 0, .08);
  }
  .selects {
    box-shadow: 0 2px 10px 2px rgba(0, 0 ,0 , 0.2), 0 0 0px 0 rgba(0, 0, 0, 0.08);
  }
  .add:hover,
  .option:hover {
    background-color: #ececec;
  }

  .option:hover {
    border-radius: 4px;
  }

  .selects {
    margin-top: 4px;
    padding: 4px;
    font-size: 12px;
    line-height: 1.25;
    border-radius: 6px;

    .option {
      width: 128px;
      padding: 8px 16px;
    }
  }
}
</style>