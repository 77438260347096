export default {
  /**首页 */
  让每个人都用上人工智能: "Bringing AI to everyone",
  简单好用的人工智能: "Simple and user-friendly",
  /**支付页面 */
  创建订单: "Place an order",
  次: "Quantity",
  原价: "Original price",
  截止: "Deadline",
  小计: "Subtotal",
  联系信息: "Contact information",
  邮箱: "Email",
  购买数量: "Quantity purchased",
  可用次数: "Number of uses",
  总计: "Total",
  请扫码支付: "Please scan the QR code to complete the payment",
  /**留言板 */
  发送: "Send",
  留言内容不得为空: "The message must not be empty",
  /**我的信息 */
  返回并提问: "back and answer",
  姓名是必填字段: "Name is a required field",
  用户配置文件更新成功:"User profile updated successfully",
  头像上传成功:"Avatar uploaded successfully",
  头像上传失败:"Avatar uploaded failed ",
  /**对话页面 */
  新聊天: "New chat",
  意见建议: "Improvement",
  请留下您的宝贵意见: "Please leave your valuable comments,think you!",
  有效期: "Remaining",
  示例: "Examples",
  性能: "Capabilities",
  限制: "Limitations",
  示例集: [
    "Explain quantum computing \n in simple terms",
    "Got any creative ideas for a 10 year old's birthday?",
    "How do I make an HTTP request in Javascript?"
  ],
  性能集: [
    "Remembers what user said earlier in the conversation",
    "Allows user to provide follow-up corrections",
    "Trained to decline inappropriate requests"
  ],
  限制集: [
    "May occasionally generate incorrect information",
    "May occasionally produce harmful instructions or baised content",
    "Limited knowledge of world and events after 2021"
  ],
  发送消息: "Send a message...",
  提示: "ChatGPT may produce inaccurate information about people, places, or facts. ChatGPT July 20 Version",
  兑换: "exchange",
  重新生成: "Regenerate response",
  停止生成: "Stop generating",
  复制代码: "Copy code",
  保存并提交: "Save & Submit",
  保存: "Save",
  关闭: "Cancel",
  仅有一次:
    "Only one message at a time. Please allow any other responses to complete before sending another message, or wait one minute.",
  今日次数已用完:"Daily limit reached",
  会话次数已用完:"Session limit reached",
  请充值:"Please recharge",
  连接已断开:"Connection disconnected ",
  秒后自动刷新页面:" seconds later, the page will refresh automatically",
  网络错误: "Network error,Please try again",
  /**我的信息 */
  我的信息: "My Information",
  退出登录: "Log out",
  用户档案: "User profile",
  昵称: "Nickname",
  名称: "Name",
  与此帐户关联的昵称: "The name associated with this account",
  电子邮箱: "The email address associated with this account",
  与此帐户关联的电子邮件地址: "The email address associated with this account",
  与此帐户关联的电话号码: "The phone number associated with this account",
  头像: "Avatar",
  与此帐户关联的头像: "The avatar associated with this account",
  绑定手机: "Bind Phone",
  绑定邮箱: "Bind Email",
  上传头像图片大小不能超过点5MB:"Profile picture upload size must not exceed 2MB",
  /**我的信息 */
  我的信息: "User Info",
  /**账单页面 */
  订单记录: "Recharge",
  账单: "Bill",
  时间: "Date",
  订单号: "No.",
  可用次数: "Token",
  花费: "Expenditure",
  方式: "Method",
  剩余: "Remaining",
  天: "day",
  多天: "days",
  payMethodType1: "Purchase ",
  payMethodType2: "System Reward",
  payMethodType3: "Registration Reward",
  payMethodType4: "Course Gift",
  payMethodType5: "Recharge operations",
  /**使用记录页面 */
  使用记录: "Usage",
  日期: "Date",
  使用情况: "Alteration",
  描述: "Description",
  充值: "Recharge",
  支付: "Payment",
  descType1: "Dialogue",
  descType2: "Expiration",
  descType3: "Course Refund",
  /**登录页面 */
  选择语言: "Languages:",
  欢迎使用中文ChatGPT: "Welcome to ChatGPT",
  用您的帐户登录: "Log in with your account to continue",
  登录: "Log in",
  注册: "Sign up",
  hello: "nihao",
  欢迎回来: "Welcome back",
  还没有账号: "Don't have an account",
  去注册: "Sign Up",
  输入你的密码: "Enter your password",
  密码: "Password",
  忘记密码: "Forgot password?",
  电子邮件无效: "Email is not valid",
  账号或密码错误: "Wrong email or password",
  使用邮箱登录: "Email Login",
  手机验证登录: "Access with Mobile",
  /**注册 */
  创建新用户: "Create your account",
  手机: "Phone Number",
  邮箱: "Email ",
  电话或邮箱:" Phone / Email ",
  确定: "Continue",
  已经有账户了: "Already have an account",
  登录: "Log in",
  验证码: "Verification Code",
  验证码已发送请勿重复获取: "Verification code has been sent, please do not request it again",
  发送至邮箱: "Send to Email",
  获取验证码: "Get Verification Code",
  确认密码: "Confirm Password",
  密码长度不超过8位: "The password length cannot be less than 8 characters",
  两次密码输入不一致: "The two password inputs are inconsistent",  
  密码不能为纯数字:"The password cannot be a pure number",
  请输入验证码: "Please enter the validate code",
  请输入密码: "Please enter password",
  请确认密码: "Please enter password",
  请输入确认密码: "Please enter the confirm password",
  邮箱不能为空: "The Email cannot be empty",
  电话号码不能为空: "Please provide a phone number",
  验证码不能为空: "The validate cannot be empty",
  验证码有误: "The validate code is incorrect",
  密码不能为空: "The password cannot be empty",
  用户不存在: "The user does not exist",
  用户已经存在: "The user already exists",
  填写格式有误: "Format input error",
  请输入合规的邮箱地址或11位大陆电话号码: "Please enter a compliant @ email address or 11-digit mainland phone number",
  请输入有效的11位手机号码:"Please enter a valid 11-digit mobile number",
  请输入有效的电子邮件地址: "Please enter a valid email address",
  在: "Try again in",
  秒后重试: "seconds",
  短信发送成功: "Message sent successfully",
  短信发送失败: "Message delivery failed",
  用户名格式无效: "Invalid username format",
  请输入正确的手机验证码: 'Please enter the correct mobile phone verification code',
  /**手机登录注册 */
  手机登录: "Quick Login for Mobile",
  验证电话号码: "Verify phone number",
  /**重置 */
  查看电子邮件: "Check Your Email",
  请检查邮箱的中最新的邮件以获取重置密码链接:
    "Please check the email address j.com for instructions to reset your password",
  重新发送邮件: "Resend email",
  发送成功: "resend success",
  重置你的密码: "Reset your password",
  请输入您的电子邮件地址或电话号码我们将向您发送重置密码的指示:
    "Please enter your email address or phone number and we will send you instructions to reset your password!",
  请在下面输入新密码以更改密码:
    "Enter a new password below to change your password.",
  无效的电子邮件地址: "Invalid email address",
  新密码: "New password",
  重新输入新密码: "Re-enter new password",
  重置密码: "Rest password",
  /**支付方式 */
  充值: "Recharge",
  去支付: "Pay Now",
  使用微信支付:"Pay with WeChat",
  二维码加载失败: "The QR code failed to load",
  /**课程兑换 */
  课程兑换: "Course Redemption",
  请输入8位兑换码: "Please enter the 8-digit redemption code",
  密码不能为纯数字: "The password must contain at least two combinations of letters and numbers, and not less than 8 characters",
  密码不能包含空格: "The password cannot contain spaces",
  修改成功: "modified successfully！",
  确认兑换: "Confirm",
  请输入正确的兑换码: "Please enter a valid redemption code.",
  兑换码已使用: "Redemption code has been used.",
  兑换码不存在: "Redemption code does not exist",
  密码至少包含字母和数字的两种组合且不小于8个字符: "The password must contain at least two combinations of letters and numbers, and not less than 8 characters",
  //------
  兑换成功: "Redemption successful",
  充值成功: "Recharge successful",
  兑换失败: "Redemption failed",
  充值失败: "Recharge failed",
  请再次尝试: "Please try again",
  返回: "Return",
  返回兑换页: "Return to redemption page",
  返回充值页: "Return to recharge page",
  获得: "Obtain",
  tokens: "tokens"
};
