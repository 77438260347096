<template>
  <div class='QI-Card flex-x-between-centerHor ' @click="showChat" :class="disableCard ? '':'mouseHead'">    
    <!-- 左侧图标区域 -->
    <div class="left flex-x-center-centerHor">
      <!-- 选择功能 -->
      <span class="icons flex-x-center-centerHor" v-if="choose">
        <i class="el-icon-plus" v-if="choose == 'create'"></i>
        <i class="iconfont icon-bangding" v-if="choose == 'bind'"></i>
      </span>
      <!-- 头像 -->
      <img v-if="avatar == ''" src="../../assets/images/icons/agent.png" alt="">
      <img v-if="avatar" :src="avatar" alt="">
      <div class="center">
        <div class="name">{{ name }}</div>
        <div class="emp_id flex-x-left-centerHor">
          <div class="emp_id_length">{{ emp_id }}</div>
          <el-tooltip class="item" effect="dark" :content="'复制'" placement="top">
            <i class="iconfont icon-fuzhi mouseHead"  @click="(event) => copyValue(emp_id, event)"></i>
          </el-tooltip>
        </div>
        <div class="desc">{{ desc }}</div>
      </div>
    </div>
    <div class="right flex-x-left-centerHor">
      <div v-if="righType == 'btns'" class="btns">
        <span class="mouseHead " v-if="!(pageType == 'bind' && release_status == '1')" :class="edit(status)"
          @click="toEdit(pageType)">
          <el-tooltip class="item" effect="dark" :content="'编辑'" placement="top"><i
              class="el-icon-edit"></i></el-tooltip>
        </span>
        <!-- <span class="mouseHead " v-if="pageType != 'create' && release_status == '1'" @click="edit('watch')"><i class="iconfont icon-check-line"></i></span> -->
        <span class="mouseHead " v-if="pageType == 'create'" :class="del(status)" @click="deleteAgent"><el-tooltip
            class="item" effect="dark" :content="'删除'" placement="top">
            <i class="el-icon-delete"></i></el-tooltip>
        </span>
        <span class="mouseHead " v-if="pageType == 'bind'" @click="unBind"><el-tooltip class="item" effect="dark"
            :content="'解绑'" placement="top"><i class="iconfont icon-jiechubangding"></i></el-tooltip></span>
      </div>
      <div v-if="righType == 'text' && created_name" class="text">by {{ created_name }}</div>
    </div>
  </div>
</template>

<script>
import { watch } from 'less';

// import { mapState } from 'vuex' // 从store中导入【全局】数据
// 定义Vue组件的业务逻辑
export default {
  // 为组件命名
  // name:'',
  // 加载子目录组件👇
  // components: {
  // } ,
  // 如果作为子组件向父组件传递
  props: {
    choose: { // xxx 引导子组件向父组件👆传值的要素,父组件里要有定义
      type: String,
      required: true
    },
    name: { // xxx 引导子组件向父组件👆传值的要素,父组件里要有定义
      type: String,
      required: true
    },
    emp_id: {
      type: String,
      required: true
    },
    desc: {
      type: String,
      required: true
    },
    avatar: {
      type: String,
      required: true
    },
    righType: {
      type: String,
      required: true
    },
    created_name: {
      type: String,
      default: null
    },
    pageType: {
      type: String,
      default: null
    },
    status: { // 检索当前AI员工状态
      type: String,
      default: null,
    },
    release_status: {// 编辑/查看状态
      type: String,
      default: null,
    },
    disableCard: {
      type: Boolean,
      required: false,
    },
  },

  // 私有数据
  data() {
    return {}
  },
  // 创建生命周期函数,立即获取所有元素
  // 当页面刚加载的时候会执行的钩子函数
  created() { },
  // 处理函数
  methods: {
    showChat(){
      this.$emit('click')
    },
    copyValue(value,event) {
      event.stopPropagation(); // 阻止事件冒泡
      // 创建一个临时的文本区域
      const textarea = document.createElement('textarea');
      textarea.value = value;
      document.body.appendChild(textarea);
      textarea.select(); // 选择文本
      document.execCommand('copy'); // 执行复制命令
      document.body.removeChild(textarea); // 移除临时文本区域
      // 可选：给用户反馈
      this.$message.success('复制成功');
    },
    toEdit(pageType) {
      this.$emit('toEdit', pageType)
    },
    edit(status) {
      if (status == '0')
        return 'unworking'
      if (status == '1')
        return 'working'
      if (status == '2')
        return 'working'
      if (status == '3')
        return 'unworking'
    },
    del(status) {
      if (status == '0') // 
        return 'unworking'
      if (status == '1') //
        return 'working'
      if (status == '2')
        return 'waitting'
      if (status == '3')
        return 'working'
    },
    deleteAgent() {
      this.$emit('deleteAgent', true)
    },
    unBind() { // 解绑，打开绑定/解绑页面
      this.$emit('unBind', false)
    },
  },

  // 将【全局】数据,映射为当前组件的计算属性
  computed: {
    
  },

  // ...其他业务逻辑
}
</script>

<style lang='less' scoped>
/**这个语法中支持less scoped只在当前组建生效，没有则全局生效 */
/**定义组件的形式 */
.QI-Card {
  width: 576px;
  padding: 12px;
  gap: 16px;
  border-radius: 4px;
  color: var(--icon-black);
  margin-bottom: 8px;

  .left {


    .icons {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 1px dashed var(--border-color);

      .el-icon-plus,
      .iconfont {
        font-size: 20px;
        font-weight: 700;
        color: var(--icon-black);
      }
    }

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 16px;
    }

    .center {
      .name {
        line-height: 1;
        font-size: 14px;
        margin-bottom: 4px;
      }

      .emp_id {
        margin: 6px 0;
        color: var(--tip-color);

        >.iconfont {
          color: var(--icon-black);
          font-size: 12px;
          margin-left: 8px;
        }
        .emp_id_length {
          display: inline-block;
        }
      }

      .desc,
      .emp_id {
        font-size: 12px;
        line-height: 1;
        width: 350px;
        white-space: nowrap;
        /* 保持文本在一行显示 */
        overflow: hidden;
        /* 隐藏溢出的内容 */
        text-overflow: ellipsis;
        /* 超出部分显示省略号 */
      }
    }
  }



  .right {
    width: 80px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; 
    overflow: hidden;
    text-overflow: ellipsis;
    

    .text {
      color: var(--tip-color);
      font-size: 12px;
    }

    .btns {
      color: var(--tip-color);

      i {
        color: var(--icon-black);
        font-size: 14px;
      }

      .el-icon-edit,
      .icon-check-line {
        margin-right: 16px;
      }
    }
  }
}

.QI-Card:hover,.active {
  background-color: var(--left-bar-bgcolor);
}

.unworking {
  pointer-events: none;
  color: gray;
  opacity: 0.6;
  cursor: not-allowed;
}

@media screen and (max-width: 1800px) {
  .QI-Card {
    width: 100% !important;

    .left {
      .center {

        .desc,
        .emp_id {
          width: 200px;
          white-space: nowrap;
          /* 保持文本在一行显示 */
          overflow: hidden;
          /* 隐藏溢出的内容 */
          text-overflow: ellipsis;

          /* 超出部分显示省略号 */
          .emp_id_length {
            width: 180px;
            white-space: nowrap;
            /* 保持文本在一行显示 */
            overflow: hidden;
            /* 隐藏溢出的内容 */
            text-overflow: ellipsis;
            /* 超出部分显示省略号 */
          }
        }
      }
    }

    .right {
      .text {
        font-size: 12px;
      }
    }
  }
}
</style>

<style lang='less' scoped>
@media screen and (max-width: 768px) {
  .QI-Card {
    width: 100%;
    
      .left {
        .center {
    
          .desc,
          .emp_id {
            width: 14.8rem;
          }
        }
      }
    }
    }
</style>