<template>
  <div>
    <!-- NavBar 导航栏 -->
    <div class="NavBar flex-x-between-centerHor ">
      <div class="sidebar" @click="navsList = !navsList">
        <i class="iconfont icon-icon_caidan"></i>
      </div>
      <div class="top">{{ linkList[titleNum].name }}</div>
      <div class="sidebar">
        <div style="width: 5rem"></div>
      </div>
    </div>
    <!-- 左侧Sidebar -->
    <el-drawer title="我是标题" direction="ltr" :visible.sync="navsList" :with-header="false">
      <links v-if="navsList" :linkList="linkList" :linkListEnd="linkListEnd" @childEvent="handleChildEvent"></links>
    </el-drawer>
  </div>
</template>

<script>
import links from "../center/links.vue"
import { userInfo } from "../../config/api.js"
import { watch } from "less";
import { mapActions, mapGetters } from 'vuex';
// 定义Vue组件的业务逻辑
export default {
  // 为组件命名
  // name:'',
  // 加载子目录组件👇
  // components: {
  // } ,
  // 如果作为子组件向父组件传递
  props: {
    titleNum: { // xxx 引导子组件向父组件👆传值的要素,父组件里要有定义
      type: Number,
      required: true
    }
  },
  components: {
    links,
  },
  // 私有数据
  data() {
    return {
      navsList: false, // 导航栏默认隐藏
      linkList: [
        { icon: "icon-AI", name: this.$t("我的AI员工"), path: "agent" },
        { icon: "icon-disanfang", name: this.$t("第三方平台"), path: "plat" },
        { icon: "icon-dingdan", name: this.$t("订单记录"), path: "bill" },
        { icon: "icon-tongji", name: this.$t("使用记录"), path: "usage" },
        { icon: "icon-chongzhi", name: this.$t("充值付费"), path: "pay" },
        { icon: "icon-userinfo1", name: this.$t("我的信息"), path: "info" },
      ],
      linkListEnd: [
        { icon: "icon-yue", name: '剩余用量：', path: "" },
        { icon: "icon-logout", name: this.$t("退出登录"), path: "/" },
      ],
    }
  },
  // 创建生命周期函数,立即获取所有元素
  // 当页面刚加载的时候会执行的钩子函数
  created() {
    this.handleResize()
      this.getuserInfo();
  },
  // 处理函数
  methods: {
    getuserInfo() {
      userInfo().then(res => {
        this.linkListEnd[0].name = '剩余用量：' + res.data.balance
        // 传值给pc和移动端左侧导航栏
        // this.$store.dispatch('updateBalance', res.data.balance)
      });
    },
    handleChildEvent(value) {
      this.navsList = value
    },
    handleResize() {
      this.pagination = window.innerWidth > 768;
    },
    ...mapActions(['fetchBalance']),
  },


  // 将【全局】数据,映射为当前组件的计算属性
  computed: {
    ...mapGetters({
      balance: 'getBalance' // 直接将getBalance映射为balance
    }),
  },
  watch: {
    balance(newVal) {
      this.linkListEnd[0].name = '剩余用量：' + newVal
    }
  }
  // ...其他业务逻辑
}
</script>

<style lang='less' scoped>
/**这个语法中支持less scoped只在当前组建生效，没有则全局生效 */
/**定义组件的形式 */
.NavBar {
  display: none;
  width: 100%;
  height: 6.6rem;
  line-height: 6.6rem;
  color: #333;
  background: #fff;
  font-size: 2.2rem;
  font-weight: 700;
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  border-bottom: 1px solid var(--border-default);

  .iconfont {
    font-size: 1.8rem;
    font-weight: 700;
    padding: 0 1.6rem;
  }

  .none {
    color: #fff;
  }
}

@media (max-width: 768px) {
  
  .NavBar {
    display: flex;
  }
}
</style>
