<template>
  <div class="links flex-y-between-start" v-if="pagination">
    <div class="sidebar">
      <p class="linkBtn" v-for="(item, index) of linkList" :key="index" @click="handleSelect(item.path)">
        <span class="push">
          <i :class="'iconfont ' + item.icon"></i>{{ item.name }}
        </span>
        <i class="el-icon-arrow-right"></i>
      </p>
    </div>
    <div class="sidebar">
      <p class="linkBtn" v-for="(item, index) of linkListEnd" :key="index" @click="handleSelect(item.path)">
        <span class="push">
          <i :class="'iconfont ' + item.icon"></i>{{ item.name }}
        </span>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "links",
  props: {
    linkList: [],
    typeof: Array,
    linkListEnd: [],
    typeof: Array,
  },
  data() {
    return {
      pagination: true
    };
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  methods: {
    handleResize() {
      this.pagination = window.innerWidth < 768;
    },
    handleSelect(key) {
      if (key === this.$route.name) return this.$emit('childEvent', false);
      if (!key) return ;
      if (key === "/") {
        
        localStorage.removeItem("userData");
      }
      this.$router.push(key);
    }
  }
};
</script>
<style scoped lang="less">
.links {
  font-size: var(--division-text);
  color: #333;
  background-color: var(--left-bar-bgcolor);
  border-top: 1px solid #ddd;
  height: 100vh;
  overflow-y: scroll;
  justify-content: space-between;
  padding: 1.4rem 1rem;

  .sidebar {
    width: 100%;
    background-color: #fff;
    border-radius: 0.5rem;

    .linkBtn {
      margin-top: 1rem;
      padding: 0 1rem 1rem 1rem;
      border-bottom: 1px solid #ddd;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .push {
        color: #333333;
      
        .iconfont {
          color: #606060;
          font-size: 1.4rem;
          margin-right: 1rem;
        }      
      }
    
      .el-icon-arrow-right {
        color: #606060;
        font-size: 1.2rem;
      }
    }
    .linkBtn:last-child{
      border: 0;
    }
  }
  .sidebar:last-child{
    margin-bottom: 9.5rem;
  }
}
</style>
