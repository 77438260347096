import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import ElementUI from "element-ui"
import alertWonderful from "./utils/alertWonderful"
import "reset-css"
import "@/assets/scss/global.scss"
import functions from "./utils/icons"
import { getDeviceClass } from "./utils/deviceUtils.js"
import GlobalPlugin from './utils/GlobalPlugin.js';
/**语言包模块 */
import VueI18n from "vue-i18n"
import en from "./locales/en-US"
import zh from "./locales/zh-CN"
import '@/assets/iconfont/iconfont.css'
import '@/assets/iconfont-colorful/iconfont.css'
import '@/assets/iconfont01/iconfont.css'
import '@/assets/iconfontUser/iconfont.css'
import "./global.css";
import * as global from './utils/global.js';
import QIButton from './components/QIButton/index.vue'; // 导入 QI-button 组件
import QIButtonBig from './components/QIButton/big.vue'; // 导入 QI-button 组件
import QIDataTable from './components/QIDataTable/index.vue'; // 导入 QI-DataTable 组件
import QINote from './components/QINote/index.vue'; // 导入 QI-Note 组件
import QICard from './components/QICard/index.vue'; // 导入 QI-Card 组件
import QIChat from './components/QIChat/index.vue'; // 导入 QI-Chat 组件
import TopBar from './components/topBar/index.vue'; // 导入 QI-Chat 组件

// 注册全局组件
Vue.component('QI-Button', QIButton);
Vue.component('QI-Button-Big', QIButtonBig);
Vue.component('QI-DataTable', QIDataTable);
Vue.component('QI-Note', QINote);
Vue.component('QI-Card', QICard);
Vue.component('QI-Chat', QIChat);
Vue.component('Top-Bar', TopBar);
Vue.prototype.$global = global;

Vue.prototype.$deviceClass = getDeviceClass()

Vue.prototype.functions = functions
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(alertWonderful)
Vue.use(VueI18n)
Vue.use(GlobalPlugin);

const messages = {
  en_US: {
    // ...enLocale,
    ...en, // 引入自定义英文翻译
  },
  zh_CN: {
    // ...zhLocale,
    ...zh, // 引入自定义中文翻译
  },
}
const i18n = new VueI18n({
  locale: "zh_CN", // 默认语言为中文
  messages,
})
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app")
