import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import theme from './theme'
import aside from './aside'
import routes from './routes'
import tabs from './Tabs'
import toast from './toast'
import breadcrumb from './breadcrumb'
import table from './table'
import { userInfo } from '../config/api'; // 确保路径正确

export default new Vuex.Store({
  state: {
    balance: null, // 添加 balance 状态
    userAvatar: '', // 添加用户头像
    userData: {},
    sessionId: null,
    equipment: function () {
      let mobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return mobile != undefined && mobile.length > 0
    }
  },
  mutations: {
    setBalance(state, payload) {
      state.balance = payload;
    },
    setUserHead(state, payload) {
      state.userAvatar = payload;
    }
  },
  actions: {
    fetchBalance({ commit }) {
      userInfo().then((res) => {
        commit('setBalance', res.data.balance); // 传入全局剩余量
        commit('setUserHead', res.data.avatar); // 传入全局头像
      }).catch((error) => {
        console.error('Error fetching balance:', error);
      });
    },
  },
  getters: {
    getBalance: state => state.balance,
    getUserAvatar: state => state.userAvatar, // 添加 getter 来获取 avatar
  },
  modules: {
    theme,
    aside,
    routes,
    tabs,
    toast,
    breadcrumb,
    table
  }
})