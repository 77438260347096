<!-- 官网聊天对话组件 -->
<template>
  <div class='QI-chat flex-y-between ' :class="inputPlac == $t('发送消息')? 'welcome':'chating'">
    <div class="skeleton-items flex-y-between" :style="'height:calc(100% - ' + footerHeight + 'px)'" v-if="waiting">
      <div class="" v-if="waiting"></div>
      <div class="" v-if="waiting"></div>
      <div class="" v-if="waiting"></div>
    </div>
    <agentView :avatar="avatar" :created_name="created_name" :desc="desc" :footerHeight="footerHeight"
      v-if="!chatOrNot && !waiting"></agentView>
    <addNewChat v-if="chatOrNot && !waiting" @click="newChat()" :emp_id="emp_id"></addNewChat>
    <!-- <agentChat v-if="chatOrNot"></agentChat> -->
    <div class="content" id="content" v-if="!waiting">
      <!-- 答问模块 -->
      <div class="top-bar">
        <span>
          <el-popover placement="bottom" width="100" trigger="click">
            <i slot="reference" class="plus iconfont icon-jurassic_setup-multilingual" style="right: 3rem;"></i>
          </el-popover>
        </span>
        <!-- <span class="plus" @click="addNewChat()">+</span> -->
      </div>

      <div v-if="sideBarOrNot" @click="sideBarOrNot = false" class="shade" @touchmove.prevent></div>

      <div class="introduce" v-if="!chatOrNot" ref="scrollArea" id="scrollArea">
        <!-- <div class="plate">ChatGPT<span class="logo">PLUS</span></div> -->

        <!-- <div class="changeLanguages">
           {{ $t("选择语言") }}
          <el-select
            v-model="lang"
            :placeholder="$t('选择语言')"
            @change="handleLanguages"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div> -->
        <!-- <div class="grid-view">
          <div class="item">
            <img class="icon" src="@/assets/icon/examples.png" />
            <span>{{ $t("示例") }}</span>
            <div
              @click="editExample(item)"
              class="text-block clickable"
              v-for="(item, index) of $t('示例集')"
              :key="index"
            >
              "{{ item }}"<i class="iconfont icon-to right"></i>
            </div>
          </div>
          <div class="item">
            <img class="icon" src="@/assets/icon/capabilities.png" />
            <span>{{ $t("性能") }}</span>
            <div
              class="text-block"
              v-for="(item, index) of this.$t('性能集')"
              :key="index"
            >
              {{ item }}
            </div>
          </div>
          <div class="item">
            <img class="icon" src="@/assets/icon/limitations.png" />
            <span>{{ $t("限制") }}</span>

            <div
              class="text-block"
              v-for="(item, index) of this.$t('限制集')"
              :key="index"
            >
              {{ item }}
            </div>
          </div>
          <div
            v-if="store.state.equipment()"
            class="item"
            style="visibility: hidden;"
          >
            <div class="text-block">占位内容</div>
          </div>
        </div> -->
      </div>
      <!-- 聊天区域 -->
      <div v-else class="chat-area" ref="scrollArea" @scroll="handleChatScroll($event)">
        <i class="iconfont icon-more" v-if="loading"></i>
        <div class="chat-item" :class="index % 2 == 1 ? 'gpt-reply' : ''" v-for="(item, index) in dialogueContent"
          :key="index" @mouseover="userItemOver(index)" @mouseout="userItemOut()">
          <div class="msg-box">
            <!-- AI员工默认头像--><img class="" v-if="index % 2 == 1" v-show="!avatar" src="../../assets/images/icons/agent.png" />
            <!-- AI员工头像--><img class="" v-if="index % 2 == 1" v-show="avatar" :src="avatar" />
            <span class="header" v-else>
              <!-- 用户默认头像 --><div class="userImg" v-if="!userAvatar">{{ userAbb }}</div>
              <!-- 用户头像 --><img v-if="userAvatar" :src="userAvatar?userAvatar:''" alt="">
            </span>
            <div class="chat-content">
              <!-- {{ item['content'] }} -->
              <span class="answer" ref="text" :key="index" v-html="item['content']"></span>
              <template v-for="(arrItem, arrIdx) of item">
                <template v-if="arrItem['type'] == 'text'">
                  <!-- 文本回答 -->
                  <textarea class="edit" v-if="index == userItemEditIdx" v-model="userEditContent"
                    :key="arrIdx"></textarea>
                  <span class="answer" v-else ref="text" :key="arrIdx + 10000" v-html="markdownToHtml(arrItem['content'],index)"></span>
                </template>
                <!-- 代码块类型 new -->
                <!-- <div
                  v-else-if="arrItem['type'] == 'code'"
                  :key="arrIdx">
                  <pre>
                    {{ arrItem['content'] }}
                    <span>
                      html<br>&lt;!DOCTYPE html&gt;<br>&lt;html&gt;<br>&lt;head&gt;<br>    &lt;title&gt;简易HTML5页面&lt;/title&gt;<br>&lt;/head&gt;<br>&lt;body&gt;<br>    &lt;h1&gt;欢迎来到简易HTML5页面&lt;/h1&gt;<br>    &lt;p&gt;这是一个示例页面，你可以根据需要进行修改和扩展。&lt;/p&gt;<br>    &lt;ul&gt;<br>        &lt;li&gt;列表项 1&lt;/li&gt;<br>        &lt;li&gt;列表项 2&lt;/li&gt;<br>        &lt;li&gt;列表项 3&lt;/li&gt;<br>    &lt;/ul&gt;<br>    &lt;img src=\image.jpg\ alt=\示例图片\    &lt;script&gt;<br>        // 在这里可以添加 JavaScript 代码<br>        console.log(\Hello, HTML5!\    &lt;/script&gt;<br>&lt;/body&gt;<br>&lt;/html&gt;<br>
                  </span></pre>
                </div> -->
                <!-- <div v-else-if="arrItem['type'] == 'code'" :key="arrIdx + 100000">
                  <div class="copy">
                    <el-button type="text" @click="copyCode($event, arrItem['content'])" ref="copy" class="copyBtn"
                      icon="el-icon-copy-document">{{ $t("复制代码") }}</el-button>
                  </div>
                  <pre v-html="arrItem['content']" class="hljs" v-hljs></pre>
                </div> -->
                <!-- 代码块类型 old -->
                <!-- <pre
                  v-else-if="arrItem['type'] == 'code'"
                  :key="arrIdx"
                  class="hljs"
                  v-hljs
                >
                  <span @click="copyCode($event,arrItem['content'])" ref="copy" class="copy"><i class="iconfont icon-copy"></i>Copy code</span>
                  <code :autodetect="true" ref="code" class="code" v-html="arrItem['content']"></code>
                </pre> -->
                <template v-else-if="arrItem['type'] == 'error'">
                  <span style="color: red;" :key="arrIdx">
                    {{ arrItem["content"] }}
                  </span>
                </template>
              </template>
              <span v-if="index % 2 == 1 &&
                index == dialogueContent.length - 1 &&
                cursorOrNot
              " class="cursor">|
              </span>
              <br />
              <!-- <div class="btns" v-if="userItemEditIdx == index">
                <button class="save" @click="submitEdit(index)">
                  {{ $t('保存并提交') }}
                </button>
                <button @click="userItemEditIdx = -1">{{ $t('关闭') }}</button>
              </div> -->
            </div>
            <!-- <i v-if="index % 2 == 0" @click="userItemEdit(index)" :style="userItemOverIdx == index
              ? 'visibility: visible'
              : 'visibility: hidden'
              " class="iconfont icon-tianxie"></i> -->
          </div>
        </div>

        <div class="chat-item" style="visibility: hidden;margin: 2vh 0;">
          <div id="bottomArea" class="msg-box">
            <span>占位内容</span>
          </div>
        </div>
      </div>
      <div class="footer" @touchmove="handleTouchMove" ref="footer">
        <!-- <button id="reGenerate" @click="reGen()" v-show="(reGenOrNot && !stopGenOrNot)">
          <i class="iconfont icon-refresh"></i>{{ $t('重新生成') }}
        </button> -->
        <!-- <button id="stopGenerate" @click="stopGen()" v-show="stopGenOrNot">
          {{ $t('停止生成') }}
        </button> -->
        <!-- 欢迎词 -->
        <div class="guides" v-if="!chatOrNot">
          <div class="guide mouseHead" v-for="(i) in chatGuides" :class="disabledSend?' disabled':''" @click="fastSend(i.value)">{{ i.value }}</div>
        </div>
        <div class="line"></div>
        <div class="input-group flex-x-center-centerHor" id="inputField" ref="inputField">
          <textarea :placeholder="inputPlac" style="height: 22px;" ref="ta" v-model="userMsg"
            @input="autoResizeTextarea" :disabled="disabledSend" @keydown="sendMessage($event)" @focus="inputFocus()"
            @blur="inputBlur()"></textarea>
          <el-tooltip v-if="!sendDisable" class="item" effect="dark" content="发送" placement="top">
            <span class="iconfont icon-send mouseHead"
              :style="!userMsg ? 'cursor: not-allowed;color: var(--dropIcon-color)' : 'var(--theme-color)'"
              @click="send()"></span>
          </el-tooltip>
          <span v-else>...</span>
        </div>
      </div>

    </div>
    <el-dialog :title="alertTitle" :visible.sync="dialogVisible" width="720px" :before-close="handleClose">
      <div class="flex-x-center-centerHor" style="height: 100%;padding-bottom: 10px;">
        <i class="el-icon-warning"></i>
        <div class="flex-y-left">
          <p>{{ alertContent.split('，')[0] }}</p>
          <p style="color: #9a9a9a;">{{ alertContent.split('，')[1] }}</p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <button class="content_label_btn" type="primary" @click="confirm()">确定</button>
        <button class="content_label_btn_default" @click="dialogVisible = false;">取消</button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import store from "../../store/index.js"
import addNewChat from "./addNewChat.vue"
import agentView from "./agentView.vue"
import {marked} from "marked";
import { marketChatSessionDetail, } from "../../config/api.js";
import { getWsAddr, recordVisit } from "../../config/request.js";
import { mapActions } from 'vuex';
import Clipboard from "clipboard";
import { mapGetters } from 'vuex';
// 定义Vue组件的业务逻辑
export default {
  // 为组件命名
  name: 'QIChat',
  // 加载子目录组件👇
  components: {
    addNewChat, agentView, 
  },
  // 如果作为子组件向父组件传递
  props: {
    avatar: { // 头像
      type: String,
      required: true
    },
    created_name: { // AI员工名称
      type: String,
      required: true
    },
    desc: { // 描述
      type: String,
      required: true
    },
    emp_id: { // 员工id
      type: String,
      required: true
    },
    chatGuides: { // 欢迎词
      type: Array,
      required: true
    },
  },

  // 私有数据
  data() {
    return {
      sendView: true, // 在chat界面
      userMsg: "", // 用户提问内容
      disabledSend: false,//输入框是否可选择
      sendDisable: false, // 禁止发送
      dialogueContent: [], // 聊天内容
      sessionIdSending: [], // 历史会话记录
      guide: [],
      itemSelectedIdx: -1,
      userItemEditIdx: -1,
      userItemOverIdx: -1,
      sideBarOrNot: false,
      chatOrNot: false, // 是否展示会话
      reGenOrNot: false,
      stopGenOrNot: false,
      WS_ADDR: getWsAddr(),
      cursorOrNot: false,
      loading: false,
      // userAvatar: "",
      dialogVisible: false,
      currentScrollHeight: 0,
      footerHeight: 164,// 底部输入框高度      
      pageSize: 10,
      pageNum: 1,
      sessionId: '', // 当前聊天记录的效验码
      waiting: true, // 等待动画
      inputPlac: '', // 输入框默认文本
      alertTitle: "", 
      alertContent: "", // 弹框组件文本
      chatLists: [],
      intervalId: null,
    }
  },
  // 创建生命周期函数,立即获取所有元素
  // 当页面刚加载的时候会执行的钩子函数
  created() {
    let that = this;

    let userData = JSON.parse(localStorage.getItem("userData"));
    this.userToken = userData["token"];
    this.userName = userData["nickname"] || userData["username"];
    this.userAbb = this.userName.substring(0, 2).toUpperCase();
    let channel = this.userToken.substring(0, 10) + Date.now();
    this.chatSocket = new WebSocket(
      `${this.WS_ADDR}/ws/market/${channel}_${this.userToken}/`
    );
    console.log("正在连接...");

    this.chatSocket.onopen = function () {
      console.log("连接成功");
      that.chatSocket.send(
        JSON.stringify({
          token: that.userToken
        })
      );
    };

    const workerBlob = new Blob([
      `
        onmessage = function(event){
          postMessage(event.data);
        }
      `
    ]);

    const workerUrl = URL.createObjectURL(workerBlob);
    this.worker = new Worker(workerUrl);

    this.chatSocket.onmessage = function (event) {
      that.worker.postMessage(event.data);
    };
    // this.chatSocket.onclose = function(event) {
    // console.log(that.dialogueContent);
    // that.dialogueContent[that.dialogueContent.length - 1][0].content = that.dialogueContent[that.dialogueContent - 1][0].content +" "+that.$t('网络错误')
    // alert(that.$t('网络错误'));
    // };
    (this.worker.onmessage = event => {
      let json_data = JSON.parse(event.data);
      console.log(json_data);
      this.cursorOrNot = true; // 光标显示
      if(json_data.emp_id == this.emp_id) { // AI员工校验，如果前端AI员工ID与后端一致，才同步sessionId，否则不能同步，会有异常报错
        this.sessionId = json_data.session_id
      }
      if (json_data["code"] == 401) {
        localStorage.removeItem("userData");
        location.href = "/welcome";
      } else {
        console.log(this.dialogueContent)
        this.dialogueContent.pop()
        this.dialogueContent.push({ content: '', type: 'text' })
        this.startTyping(json_data.content)
        .then(() => {
          resolve()
        })
        .catch((error) => {
        })
        
        this.fetchBalance();// 调用 action 来更新 balance

        // if (this.switching) {
        //   // console.log(json_data['instruct']);
        //   that.reGenOrNot = true; // 重新生成按钮显示
        //   that.stopGenOrNot = false; // 重新生成按钮显示
        //   that.sendDisable = false; // 发送按钮显示
        //   that.cursorOrNot = false; // 光标隐藏
        //   clearInterval(that.renderInterval);
        // } else {
        // if (json_data["instruct"] == "start") {
        //   that.chatBusy = true
        //   // console.log(this.sessionId);
        //   // console.log(json_data["session_id"]);
        //   that.sessionId = json_data["session_id"];
        //   // that.stopGenOrNot = true;

        //   // console.log(json_data);
        //   //   如果切换历史消息并且占用会话中，不吐字
        //   that.renderInterval = setInterval(function () {
        //     if (that.renderCache.length > 0) {
        //       let json_item = that.renderCache.shift();

        //       if (json_item["instruct"] == "end") {
        //         // that.switching = false
        //         // that.chatBusy = false
        //         clearInterval(that.renderInterval);

        //         that.lastItem = that.dialogueContent.pop();
        //         for (let i = 0; i < that.lastItem.length; i++) {
        //           if (that.lastItem[i].type == "text") {
        //             if (
        //               that.lastItem[i].content.indexOf("正在思考...") !== -1
        //             ) {
        //               that.lastItem[i].type = "error";
        //               that.lastItem[i].content = ""; // 网络错误
        //             } else {
        //               that.lastItem[i].content = that.markdownToHtml(
        //                 that.lastItem[i].content
        //               );
        //             }
        //           }
        //         }

        //         that.dialogueContent.push(that.lastItem);
        //         that.scrollToChatBottom();
        //         console.log(that.dialogueContent)
        //         that.reGenOrNot = true;
        //         that.cursorOrNot = false;
        //         that.stopGenOrNot = false;
        //         that.sendDisable = false;
        //         that.showModel = true;
        //         that.sessionId = json_item["session_id"];
        //         if (that.dailyBalance > 0) {
        //           that.dailyBalance -= 1;
        //         }
        //         // setTimeout(() => {
        //         //   that.loadUserChatSession();
        //         // }, 1500);
        //         return that.getuserInfo();
        //       }

        //       that.lastItem = that.dialogueContent.pop();
        //       if (json_item["mode"] == "code") that.cursorOrNot = false
        //       if (that.lastItem.length == 1) {
        //         if (
        //           that.lastItem
        //             .slice(-1)[0]
        //             .content.indexOf("正在思考...") !== -1
        //         ) {
        //           that.lastItem[that.lastItem.length - 1].content = "";
        //         }
        //       }
        //       if (that.lastItem.slice(-1)[0].type == json_item["mode"]) {
        //         that.lastItem[that.lastItem.length - 1].content +=
        //           json_item["content"];
        //         that.lastItem[that.lastItem.length - 1].uuid =
        //           json_item["uuid"];
        //       } else {
        //         that.lastItem.push({
        //           content: json_item["content"],
        //           type: json_item["mode"],
        //           uuid: json_item["uuid"]
        //         });
        //       }
        //       if (that.lastItem.slice(-1)[0].content !== undefined) {
        //         that.lastItem[
        //           that.lastItem.length - 1
        //         ].content = that.lastItem
        //           .slice(-1)[0]
        //           .content.replace(/<>/g, "");
        //       }
        //       that.dialogueContent.push(that.lastItem);
        //       that.scrollToChatBottom();
        //     } if (that.chatSocket.readyState === WebSocket.CLOSED) {

        //       return that.dialogVisible = true;
        //     } else {
        //       that.lastItem = that.dialogueContent.slice(-1);

        //       if (that.lastItem.type == "error") {
        //         that.dialogueContent.pop();
        //         that.dialogueContent.push([that.lastItem]);
        //         that.reGenOrNot = true;
        //         that.cursorOrNot = false;
        //         that.stopGenOrNot = false;
        //         that.sendDisable = false;
        //         clearInterval(that.renderInterval);
        //         return;
        //       }
        //     }
        //   }, 100);
        // } else {
        //   if (json_data["mode"] == "error") {
        //     // that.switching = that.chatBusy = false
        //     clearInterval(that.renderInterval);
        //     if (json_data["type"] == 1) {
        //       alert(json_data["content"]);
        //     } else if (json_data["type"] == 0) {
        //       that.dialogueContent.pop();
        //       that.dialogueContent.push([
        //         {
        //           content: json_data["content"],
        //           type: json_data["mode"]
        //         }
        //       ]);
        //     }
        //     that.reGenOrNot = true;
        //     that.cursorOrNot = false;
        //     that.stopGenOrNot = false;
        //     that.sendDisable = false;

        //     return;
        //   }
        //   that.renderCache.push(json_data);
        // }
      }
      // if (json_data['instruct'] === 'end') {
      //   that.disabledSend = false // chat吐字完毕，可以输入
      //   setTimeout(() => {
      //     console.log('ok');
      //     that.sessionIdSending[that.sessionIdSending.length - 1].chatBusy = false
      //     console.log(that.sessionIdSending)
      //     that.loadUserChatSession();
      //     return that.chatBusy = false
      //   }, 2500);
      // }
      // }
    })
  },
  mounted() {
    // 调用简化后的方法，并保存返回的停止监听函数
    const stopWatching = this.watchFooter();
    // 在组件销毁时停止监听
    this.$once('hook:beforeDestroy', stopWatching);
    this.loadChatSessionDetail()
    this.$store.dispatch('fetchBalance');    
  },
  // 处理函数
  methods: {

    startTyping(content) { 
      // 生成文字
      return new Promise((resolve, reject) => {
        let typedText = ""
        let currentIndex = 0
        this.intervalId = setInterval(() => {
          // 获取下一个要添加的字符
          const nextChars = content.slice(currentIndex, currentIndex + 2); // 获取两个字符
          typedText += nextChars
          currentIndex+= 2
          this.dialogueContent[this.dialogueContent.length-1].content = typedText
          this.scrollToChatBottom()
          // console.log('dialogueContent[this.dialogueContent.length-1] ',this.dialogueContent[this.dialogueContent.length-1])
          
          if (currentIndex >= content.length ) {
            clearInterval(this.intervalId)
              resolve() // 执行成功时调用 resolve()
              this.disabledSend = false
              this.sendDisable = false // 发送按钮显示
              this.cursorOrNot = false; // 光标隐藏
          }
        }, 100)
      })
    },
    watchFooter() {
      const resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          this.footerHeight = entry.contentRect.height; // 更新 footer 高度
          console.log(this.footerHeight)
        }
      });

      // 确保使用 $refs 访问 DOM 元素
      const footerElement = this.$refs.footer;
      if (footerElement) {
        resizeObserver.observe(footerElement); // 开始监听
      }

      // 返回一个函数，用于在需要时停止监听
      return () => {
        if (footerElement) {
          resizeObserver.unobserve(footerElement)
        } else {
          // console.warn('要取消观察的元素已不存在')
          return;
        }
      };
    },
    newChat() { // 创建新聊天
      // console.log(1)
      this.chatOrNot = false
      this.sessionId = ''
      this.inputPlac = this.$t('发送消息')
      // 使用 filter 方法过滤掉具有指定 emp_id 的项
      this.chatLists = this.chatLists.filter(i => i.emp_id !== this.emp_id);
      clearInterval(this.intervalId)      
    },
    inputBlur() {
      this.inputOrNot = false;
      this.isInputFocused = false;
    },
    sendMessage(event) {
      // 添加`shift`+'enter'
      if (event.key === "Enter" && event.shiftKey) {
        event.preventDefault(); // 阻止默认的换行操作
        this.userMsg += "\n"; // 插入换行符
        const textarea = this.$refs.ta;
        textarea.style.height = "auto"; // 先将高度设置为 auto，以便正确计算内容高度
        console.log(textarea.scrollHeight);
        textarea.style.height = textarea.scrollHeight + "px"; // 设置高度为内容的实际高度
        if (textarea.scrollHeight > 40) {
          textarea.style.height = textarea.scrollHeight + 18 + "px"; // 设置高度为内容的实际高度
        }
        return;
      }
      if (event.keyCode == 13) {
        event.preventDefault();
        this.send();
      }
    },
    inputFocus() {
      this.inputOrNot = true;
      let that = this;
      // this.$nextTick(() => {
      //   that.scrollToChatBottom()
      // })
      that.isInputFocused = true;
    },
    autoResizeTextarea(event) {
      let element = event.target;
      if (store.state.equipment()) {
        element.style.height = "44px";
        element.style.maxHeight = "200px";
      } else {
        element.style.height = "22px";
        element.style.maxHeight = "200px";
      }

      element.style.height = `${element.scrollHeight}px`;
      // 新的高度自适应逻辑
      // this.adjustTextareaHeight();//实现文本域的行高跟随字体自适应
    },
    fastSend(value) {
      this.userMsg = value
      this.send()
    },
    send() {
      if (!this.userMsg) { // 当输入框无值，禁止点击
        return;
      }
      if (!this.chatOrNot) { // 当从首页进入后，先清空this.dialogueContent的缓存数据
        this.dialogueContent = []
      }
      // console.log('switching ', this.switching);
      // console.log('chatBusy ', this.chatBusy);
      this.disabledSend = true
      let that = this;

      if (this.balance <= 0) {
        // this.$msgbox(this.$t('请充值'), this.$t('会话次数已用完'), {
        //   confirmButtonText: '确定',
        //   cancelButtonText: '',
        // }).then(() => {
        //   this.$router.push('/bill')
        // }).catch(() => {
        // });
        this.disabledSend = false
        return this.alertBubble('温馨提示', '请充值，会话次数已用完');
      }
      this.inputPlac = this.$t('继续对话')

      if (this.chatBusy === true) {
        // console.log(this.chatOrNot);
        if (this.chatOrNot) {
          // this.dialogueContent.pop();
        } else {
          this.chatOrNot = true
        }
        this.dialogueContent.push(
          ...[
            [
              {
                content: this.userMsg,
                type: "text"
              }
            ],
            [
              {
                content: this.$t('仅有一次'),
                type: "text"
              }
            ]
          ]
        )
        // if (this.newChatBtn) {
        this.reGenOrNot = true
        // }
        this.userMsgReGen = this.userMsg
        this.userMsg = ''
        return;
      }
      if (this.sendDisable) {
        return;
      }

      if (this.userMsg !== "") {
        if (
          this.chatSocket.readyState === WebSocket.CLOSING ||
          this.chatSocket.readyState === WebSocket.CLOSED
        ) {
          alert("连接已断开");
          console.log("连接已断开");
          this.userMsg = ''
          return;
        } else {
          this.chatOrNot = true;
          this.dialogueContent.push(
            ...[
              [
                {
                  content: this.userMsg,
                  type: "text"
                }
              ],
              [
                {
                  content: "正在思考...",
                  type: "text"
                }
              ]
            ]
          );
          const textarea = this.$refs.ta;
          textarea.style.height = 22 + "px"; // 设置高度为内容的实际高度
          this.sendDisable = true;
          this.stopGenOrNot = true
          console.log("send...");
          // this.switching = false
          // console.log(this.userMsg);
          // console.log(this.sessionId);
          // console.log(this.userName);
          this.sessionIdSending = this.sessionIdSending.filter(item => item.sessionId !== this.sessionId);
          this.sessionIdSending.push({ sessionId: this.sessionId, chatBusy: true });
          console.log(this.sessionIdSending);
          let emp_id = this.emp_id;
          let data = {
            msg: this.userMsgReGen || this.userMsg,
            session_id: this.sessionId,
            username: this.userName,
            msg_id: JSON.stringify(Date.now()),
            emp_id: emp_id,
          };

          // 判断emp_id的值是否为空
          if (emp_id && data.model !== 'GPT-3.5' && data.model !== 'GPT-4.0') { // 在有emp_id的时候，判断该模型不是GPT 3.5或4.0，才能传入emp_id
            // 如果emp_id有值，则将其添加到data对象中
            data.emp_id = emp_id;
          }
          console.log(JSON.stringify(data))
          this.chatSocket.send(JSON.stringify(data))
          // this.reGenOrNot = false;
          // this.cursorOrNot = true;
        }
        this.userMsg = "";
        // this.userMsgReGen = ""
        this.scrollToChatBottom();
      }
    },
    handleTouchMove(event) { },
    scrollToChatBottom() {
      let that = this;
      this.$nextTick(() => {
        let area = that.$refs.scrollArea;
        console.log(area)
        area.scrollTop = area.scrollHeight;
      });
    },
    sendMessage(event) {
      // 添加`shift`+'enter'
      if (event.key === "Enter" && event.shiftKey) {
        event.preventDefault(); // 阻止默认的换行操作
        this.userMsg += "\n"; // 插入换行符
        const textarea = this.$refs.ta;
        textarea.style.height = "auto"; // 先将高度设置为 auto，以便正确计算内容高度
        console.log(textarea.scrollHeight);
        textarea.style.height = textarea.scrollHeight + "px"; // 设置高度为内容的实际高度
        if (textarea.scrollHeight > 40) {
          textarea.style.height = textarea.scrollHeight + 18 + "px"; // 设置高度为内容的实际高度
        }
        return;
      }
      if (event.keyCode == 13) {
        event.preventDefault();
        this.send();
      }
    },
    // 点击文本框事件
    inputFocus() {
      this.inputOrNot = true;
      let that = this;
      this.$nextTick(() => {
        that.scrollToChatBottom()
      })
      that.isInputFocused = true;
    },
    userItemOver(idx) {
      if (this.userItemEditIdx == -1) {
        this.userItemOverIdx = idx;
      }
    },
    userItemOut(idx) {
      if (this.userItemEditIdx == -1) {
        this.userItemOverIdx = -1;
      }
    },
    alertBubble(title, content, emp_id, value) {
      this.dialogVisible = true;
      this.alertTitle = title
      this.alertContent = content
    },
    handleClose() {
      this.dialogVisible = false;
    },
    confirm(){
      this.$router.push('/pay')
    },
    // userItemEdit(idx) {
    //   this.userItemEditIdx = idx;
    //   this.userEditContent = this.dialogueContent[idx][0]["content"];
    // },
    // submitEdit(idx) {
    //   if (this.dailyBalance <= 0) {
    //     this.$msgbox(this.$t('请充值'), this.$t('会话次数已用完'), {
    //       confirmButtonText: '确定',
    //       cancelButtonText: '',
    //     }).then(() => {
    //       this.$router.push('/recharge')
    //     }).catch(() => {
    //     });
    //     return;
    //   }
    //   if (this.switching === true && this.chatBusy === true) {
    //     this.dialogueContent.pop();
    //     this.dialogueContent.push(
    //       ...[
    //         [
    //           {
    //             content: this.$t('仅有一次'),
    //             type: "text"
    //           }
    //         ]
    //       ]
    //     )
    //     return;
    //   }
    //   this.dialogueContent[idx][0]["content"] = this.userEditContent;

    //   let lastItem = this.dialogueContent.splice(idx + 1);

    //   let uuid = lastItem[0][lastItem[0].length - 1]["uuid"];
    //   if (uuid == undefined) {
    //     uuid = this.dialogueContent[idx][0]["uuid"];
    //   }

    //   this.userItemEditIdx = -1;

    //   if (
    //     this.chatSocket.readyState === WebSocket.CLOSING ||
    //     this.chatSocket.readyState === WebSocket.CLOSED
    //   ) {
    //     console.log("连接已断开");
    //     alert("连接已断开");
    //   } else {
    //     this.dialogueContent.push(
    //       ...[
    //         [
    //           {
    //             content: "正在思考...",
    //             type: "text",
    //             uuid: uuid
    //           }
    //         ]
    //       ]
    //     );
    //     this.disabledSend = true // 禁止输入框输入
    //     this.reGenOrNot = false;
    //     this.cursorOrNot = true;
    //     this.stopGenOrNot = true
    //     this.sendDisable = true;
    //     this.switching = false
    //     this.sessionIdSending = this.sessionIdSending.filter(item => item.sessionId !== this.sessionId);
    //     this.sessionIdSending.push({ sessionId: this.sessionId, chatBusy: true });
    //     console.log(this.sessionIdSending);
    //     const data = {
    //       instruct: "replay",
    //       session_id: this.sessionId,
    //       uuid: uuid,
    //       username: this.userName,
    //       msg: this.userEditContent
    //     }
    //     // 判断emp_id的值是否为空
    //     if (this.emp_id) {
    //         // 如果emp_id有值，则将其添加到data对象中
    //         data.emp_id = this.emp_id;
    //       }
    //       console.log(data)
    //     this.chatSocket.send(
    //       JSON.stringify(data)
    //     );
    //     this.reGenOrNot = false;
    //   }
    // },
    // copyCode(event, content) {
    //   const clipboard = new Clipboard(".copy", {
    //     text: () => {
    //       return content.replace(/<br>/g, "\r\n");
    //     }
    //   });
    //   let that = this;

    //   clipboard.on("success", () => {
    //     event.target.textContent = "Copy Success!";
    //     clipboard.destroy();
    //     setTimeout(function () {
    //       event.target.textContent = "Copy code";
    //     }, 3000);
    //   });
    //   clipboard.on("error", () => { });
    // },
    handleChatScroll(event) { // 获取历史消息
      this.loading = false;
      if (event.target.scrollTop == 0) {
        console.log(event.target.scrollTop)
        this.pageNum += 1;
        if (this.pageNum <= this.pageTotal) {
          this.loading = true;
          // if (!this.switching)
          this.loadChatSessionDetail() // 当切换到其他会话时 不需要触发 请求数据接口 否则会重复显示
        }
      }
    },
    loadChatSessionDetail() {
      let that = this;
      marketChatSessionDetail(this.pageNum, this.pageSize, this.emp_id).then(res => {
        this.waiting = false
        if (!res.data.dataset.length) {
          this.inputPlac = this.$t('发送消息')
          return that.chatOrNot = false // 会话记录为空
        }
        if (that.pageNum == 1) {
          this.inputPlac = this.$t('继续对话')

          this.gptModel = res.data.version;

          res.data["dataset"].reverse().forEach(obj => {
            let user = [
              {
                content: obj.user,
                type: "text",
                uuid: obj.uuid
              }
            ];
            let assistant = JSON.parse(obj.assistant.replace(/\n/g, "<br>"));
            // console.log(assistant);
            // chat.getChatModify(assistant)
            // if (assistant[0].content == "") {
            //   assistant[0].content = "网络错误";
            //   assistant[0].type = "error";
            // }

            that.dialogueContent.push(...[user, assistant]);
            // console.log(that.dialogueContent);
          });
        } else {
          res.data["dataset"].forEach(obj => {
            let user = [
              {
                content: obj.user,
                type: "text",
                uuid: obj.uuid
              }
            ];
            let assistant = JSON.parse(obj.assistant.replace(/\n/g, "<br>"));
            // chat.getChatModify(assistant)
            that.dialogueContent.unshift(
              ...[user, assistant]
            );
          });
        }
        that.chatOrNot = true
        that.sessionId = res.data["session_id"]
        that.pageTotal = res.data["total"];
        that.loading = false;

        if (that.pageNum == 1) {
          that.scrollToChatBottom(); // 滚动条置底
        } else {
          that.$nextTick(() => {
            that.scrollToChatBottom(); // 滚动条置底
            const scrollArea = that.$refs.scrollArea;
            scrollArea.scrollTop =
              scrollArea.scrollHeight - that.currentScrollHeight;
            that.currentScrollHeight = scrollArea.scrollHeight;
          });
        }

        if (that.pageNum == that.pageTotal) {
          that.showModel = true;
        }

        that.sideBarOrNot = false;
        that.reGenOrNot = true;
        console.log(that.dialogueContent);
        const element = { emp_id: this.emp_id, chatList: that.dialogueContent }
        that.chatLists.push(element)
        console.log(that.chatLists)
      })
    },
    markdownToHtml(content, index) {
      // console.log(content)
      
      if ((content.includes("###") || content.includes("##")) && index % 2 == 1) {
        return marked(content);
      } else {
        return content;
      }
    },
    ...mapActions(['fetchBalance']),
  },

  // 将【全局】数据,映射为当前组件的计算属性
  computed: {
    ...mapGetters({
      userAvatar: 'getUserAvatar', // 映射头像
      balance: 'getBalance', // 映射剩余量
    }),
  },
  watch: {
    created_name(newVal) {
      this.waiting = true
      this.sessionId = ''
      this.dialogueContent = []
      // 数据录入缓存，减少接口调用
      let found = false; // 用于标记是否找到匹配的 emp_id
      console.log(this.chatLists)
      for (let index = 0; index < this.chatLists.length; index++) {
        if (this.emp_id == this.chatLists[index].emp_id) {
          console.log(this.emp_id)
          this.dialogueContent = this.chatLists[index].chatList;
          console.log(this.dialogueContent)
          this.waiting = false;
          this.chatOrNot = true
          found = true; // 找到匹配的 emp_id
          break; // 找到后跳出循环
        }
      }

      // 如果没有找到匹配的 emp_id，则调用加载聊天会话详情的方法
      if (!found) {
          this.loadChatSessionDetail();
      }
    },
    emp_id(newVal) {
      const closeTyping = setTimeout(()=> {
        this.disabledSend = false
        this.sendDisable = false // 发送按钮显示
        this.cursorOrNot = false; // 光标隐藏
        clearInterval(this.intervalId)
        clearInterval(closeTyping)
      }, 5000)
    }
  },
  beforeDestroy(){
  }
  // ...其他业务逻辑
}
</script>

<style lang='less' scoped>
/**这个语法中支持less scoped只在当前组建生效，没有则全局生效 */
/**定义组件的形式 */
.QI-chat {
  height: 100%;
  color: var(--icon-black);
  position: relative;
  justify-content: space-between;
  font-weight: 400;

  .content {
    // flex-basis: 80%;
    overflow-y: auto;
    bottom: 0;
    text-align: center;
    width: 100%;

    .top-bar {
      background-color: #202123;
      color: white;
      padding: 1rem 0;
      text-align: center;
      position: relative;
      height: 4.5rem;
      display: none;

      .icon-caidan {
        position: absolute;
        left: 1rem;
        vertical-align: middle;
        font-size: 16px;

        &:hover {
          cursor: pointer;
        }
      }

      .plus {
        position: absolute;
        right: 1rem;
        font-size: 16px;
        vertical-align: middle;

        &:hover {
          cursor: pointer;
        }
      }

    }

    .introduce {

      // padding-top: 3%;
      .plate {
        position: absolute;
        left: 50%;
        top: 48%;
        transform: translate(-50%, -50%);
        color: #d9d9e3;
        display: flex;
        justify-content: center;
        align-items: center;
        // margin 20rem
        font-weight: 600;
        font-size: 32px;

        .logo {
          margin-left: .5rem;
          padding: .5rem .5rem;
          background-color: #fbe499;
          color: #9d8639;
          font-size: 1rem;
          line-height: 1rem;
          border-radius: .5rem;
        }
      }

      .title {
        font-weight: 800;
        font-size: 3rem;
        line-height: 2.5rem;
      }

      .grid-view {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: 3% auto 3% auto;

        .item {
          width: 33.3%;
          text-align: center;

          .icon {
            width: 2rem;
            height: 2rem;
            margin: 0 auto 4% auto;
            display: block;
          }

          .text-block {
            font-size: 14px;
            transform: scale(0.8);
            background-color: rgb(247, 247, 248);
            padding: 4%;
            border-radius: 0.375rem;
          }

          .clickable:hover {
            cursor: pointer;
            background-color: rgb(217, 217, 227);
          }

          .icon-toright {
            color: black;
            font-size: 18px;
            font-weight: bold;
          }

        }
      }
    }

    .chat-area {
      padding: 0 0 9rem 0;
      height: 100%;
      overflow-y: auto;
      background-color: #fff;

      .icon-more {
        font-size: 36px;
        color: #ccc;
      }

      .chat-item {
        background-color: #fff;
        border-bottom: 1px solid #ccc;
        padding: 2rem 0;
        font-size: 14px;

        .msg-box {
          width: 100%;
          padding: 0 125px;
          text-align: left;
          margin: 0 auto;
          display: flex;
          flex-direction: row;

          .GPT-4 {
            background-color: #944ff0
          }

          .GPT-3 {
            background-color: #0fa47f
          }

          span {
            line-height: 1;
            word-wrap: break-word;
            /deep/ ul li ol li a{
              color: #23527c !important;text-decoration: underline;
            }
            .userImg {
              line-height: 30px;
            }
          }

          .chat-content {
            .answer {
              // width: 668px;
              // display: inline-block;
            }
          }

          .header {
            color: white;
            min-width: 30px;
            height: 30px;
            // line-height: 30px;
            margin-left: 0;
            text-align: center;
            border-radius: 50%;

            .userImg {
              background-color: rgb(67, 126, 180);
              border-radius: 50%;
            }
          }

          img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
          }

          .chat-content {
            // width: 95%;
            padding-left: 3%;
            padding-top: 4px;
            white-space: pre-wrap;
            text-align: justify;

            .hljs {
              margin-left: 3%;
              position: relative;
              background-color: black;
              border: 0;
              border-radius: 0 0 4px 4px;

              .copy {
                font-size: 12px;
                color: white;
                position: absolute;
                right: .5rem;
                top: .5rem;
                transform: scale(0.8);
                background-color: rgb(52, 53, 65);
                padding: .4rem;
                border-radius: 10px;

                &:hover {
                  cursor: pointer;
                }

                .icon-copy {
                  font-size: 12px;
                  margin-right: .5rem;
                }
              }
            }

            .copy {
              margin-left: 3%;
              background-color: #343541;
              border-radius: 4px 4px 0 0;
              display: flex;
              justify-content: flex-end;
            }

            .copyBtn {
              color: #d9d9e3;
              margin-right: 1rem;
            }

            .edit {
              outline: none;
              border: none;
              resize: none;
              width: 100%;
            }

            .btns {
              width: 100%;
              text-align: center;

              button {
                outline: none;
                background-color: white;
                border-color: rgba(0, 0, 0, .1);
                font-size: .875rem;
                line-height: 1.25rem;
                padding: 0.5rem 0.75rem;
                border-radius: 0.25rem;
                border-width: 1px;

                &:hover {
                  cursor: pointer;
                  background-color: rgb(236, 236, 241);
                }
              }

              .save {
                margin-right: .5rem;
                background-color: rgb(16, 163, 127) !important;
                color: white;

                &:hover {
                  cursor: pointer;
                  background-color: rgb(26, 127, 100) !important;
                }
              }
            }
          }

          .icon-tianxie {
            font-size: 12px;
            opacity: 0.5;
            visibility: hidden;
          }

          .icon-tianxie:hover {
            cursor: pointer;
          }

        }
      }

      .gpt-reply {
        background-color: var(--left-bar-bgcolor);
      }
    }

    .footer {
      bottom: 0;
      right: 20px;
      margin: 0 auto;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0%);
      position: absolute;
      width: 100%;
      padding-right: 3px;
      background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0), #fff 75%);
      padding: 0 125px;

      .guides {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* 创建两列，每列占据可用空间的1份 */
        grid-gap: 6px;
        /* 设置网格项之间的间隔 */
        font-size: 14px;
        margin: auto;
        padding-bottom: 20px;

        .guide {
          background-color: #fff;
          text-align: left;
          padding: 11px 15px;
          border-radius: 8px;
          line-height: 1;
          border: 1px solid var(--button-default-border);
        }
        .disabled {
          pointer-events: none; /* 禁止鼠标事件 */
          opacity: 0.5; /* 改变透明度，表示禁用状态 */
          cursor: not-allowed; /* 设置鼠标光标为禁用状态的图标 */
        }
      }

      .input-group {
        width: 100%;
        height: 36px;
        background-color: #fff;
        margin: 0 auto 0 auto;
        text-align: center;
        border-radius: .8rem;
        border: 1px solid var(--button-default-border);
        margin-bottom: 40px;

        textarea {
          padding-left: 15px;
          width: 100%;
          height: 18px;
          line-height: 18px;
          overflow-y: scroll;
          border: none;
          outline: none;
          resize: none;
        }

        .iconfont {
          margin-right: 12px;
        }
      }

      .tips {
        color: #555;
        background-color: #fff;
        font-size: .75rem;
        padding: 1rem 1rem 2rem 1rem;
      }

      #reGenerate,
      #stopGenerate {
        background-color: rgb(255, 255, 255);
        border-color: rgba(0, 0, 0, .1);
        border-width: 1px;
        font-size: .875rem;
        line-height: 2rem;
        border-radius: .25rem;
        padding: .5rem .75rem;
        outline: none;
        margin-bottom: 1.25rem;

        .icon-refresh {
          font-size: 12px;
          margin-right: .5rem;
        }
      }

      #reGenerate:hover,
      #stopGenerate:hover {
        background-color: rgb(236, 236, 241);
      }
    }

  }

}
.cursor {
  display: inline-block;
  // width: 2px;
  animation: blink 1s ease-in-out infinite;
}

.welcome{}
.chating{background-color: #fff;}
a {
  color: #0000ee !important;
}
@keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@media screen and (max-width: 1800px) {
  .QI-chat {
    .content {
      .chat-area {
        .chat-item{
          .msg-box {
            width: 383px;
            padding: 0;
          }
        }
      }
      .footer {
        margin: 0 auto;
        padding: 0;


        .input-group,
        .guides {
          width: 383px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .QI-chat {
    .content { 
      // flex-basis: 80%;
      min-height: 30rem;

      .chat-area {
        .chat-item{
        padding-left: 20px;
        padding-right: 20px;
          .msg-box {
            width: 100%;
          }
        }
      }
      
      .footer {
        margin: 0 auto;
        padding: 0;
        padding-left: 20px;
        padding-right: 20px;
        
        .input-group,
        .guides {
          width: 100%;
        }
      }
    }
  }
}
</style>
